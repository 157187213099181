import helpers from '@/assets/js/shared/misc/helpers';
const NBars = require('nbars/commonjs.js');
import shouldRedirectToResults from './helpers/shouldRedirectToResults';
import getOptOutProviderIds from './helpers/getOptOutProviderIds';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';

// Opt out list of provider IDs
let optOuts = [];

// Opt out params
const optOutNameAndLocation = [
  {
    firstName: 'Nina',
    lastName: 'Mladjova',
    city: 'East-Palo-Alto',
    state: 'CA'
  },
  {
    firstName: 'Daniel',
    lastName: 'Hoentzsch',
    city: 'Lakewood',
    state: 'CO'
  }
];

const landingHelper = {
  ...helpers,
  shouldRedirectToResults,
  hasOptOut: async function(query, $bugsnag = {}) {
    const providerId = query.providerID || '';
    const firstNameQuery = query.firstName || '';
    const lastNameQuery = query.lastName || '';
    const stateQuery = query.state || '';
    const cityQuery = query.city || '';

    // If provider ID is in opt out list, redirect to home page
    try {
      optOuts = await getOptOutProviderIds();
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
    }
    if (optOuts.includes(providerId)) {
      return true;
    }

    // Otherwise check if name and location are in opt out list
    return optOutNameAndLocation.some(
      optOut =>
        optOut.firstName === firstNameQuery &&
        optOut.lastName === lastNameQuery &&
        optOut.city === cityQuery &&
        optOut.state === stateQuery
    );
  },
  // This method is specific to CheckPeople's landing flow
  /**
   * Gets alternate landing pages' data via endpoint server side
   */
  async getPayloadOrRedirect(
    { ChannelPeople, ChannelPeopleFull },
    stepUrl,
    url,
    redirect,
    route,
    query,
    req,
    stepTwoD,
    payload = {},
    $bugsnag = {}
  ) {
    payload.itemsPerPage = 12;
    payload.maxPages = 1;
    payload.limit = payload.itemsPerPage * payload.maxPages;

    const queryParams = Object.keys(query);
    let providerId = route.query.providerID;
    let firstNameQuery = route.query.firstName || '';
    let lastNameQuery = route.query.lastName || '';
    let stateQuery = route.query.state || '';
    let cityQuery = route.query.city || '';

    let hasOptOutId = false;
    try {
      hasOptOutId = await this.hasOptOut(query, $bugsnag);
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
    }
    if (hasOptOutId) {
      return redirect(302, '/');
    }

    const formatQueryParams = function(params) {
      return params
        .map(key => {
          if (key === 'firstName') {
            query[key] = helpers
              .setTitleCase(firstNameQuery)
              .replace(helpers.spaceRegEx, '_')
              .replace(helpers.dashRegEx, '');
          }

          if (key === 'lastName') {
            query[key] = helpers
              .setTitleCase(lastNameQuery)
              .replace(helpers.spaceRegEx, '_');
          }

          // Do not return query param if empty to prevent null/undefined as string value on results page
          if (!query[key]) {
            return;
          }

          if (key === 'state') {
            query[key] = helpers.getStateAbbrFromName(stateQuery);
          }

          if (key === 'city') {
            query[key] = helpers.formatName(
              cityQuery.replace(helpers.underscoreRegEx, ' ')
            );
          }

          return key + '=' + query[key];
        })
        .join('&');
    };

    // If no provider ID, redirect to correct page
    if (!providerId) {
      // No name redirects to home page
      if (!firstNameQuery || !lastNameQuery) {
        return redirect(302, '/');
      }

      // Otherwise redirect to results page
      const queryString = formatQueryParams(queryParams);

      let redirectUrl = url + '?' + queryString;

      return redirect(302, redirectUrl);
    }

    try {
      payload.response = await ChannelPeopleFull.byCid(providerId);
      if (this.responseError(payload.response, 'error-tlo-peoplefull-cid')) {
        payload.response = await ChannelPeople.byCid(providerId);
        // TODO: Remove this once back-end fixes bug with TLO full calls sometime failing
        if (this.responseError(payload.response, 'error-tlo-people-cid')) {
          throw Error(payload.response.msg);
        } else {
          // Payload response from teaser version is wrapped in an array so we have to access the first object
          payload.response = payload.response[0];
        }
      }
    } catch (err) {
      // Log error and redirect since something went wrong
      console.error(err);
      $bugsnag?.notify(err);
      // Remove invalid provider id from our query params
      const providerIdIndex = queryParams.indexOf('providerID');
      queryParams.splice(providerIdIndex, 1);
      // Format the query params
      const queryString = formatQueryParams(queryParams);
      // Create redirect url
      let redirectUrl = url + '?' + queryString;
      // Redirect to Results Page
      return redirect(302, redirectUrl);
    }

    let totalAssets = this.getPersonTotalAssets(payload.response);
    totalAssets = totalAssets ? this.formatMoney(totalAssets) : 0;
    const totalPhoneNumbers = payload.response.phone_numbers
      ? payload.response.phone_numbers.length
      : 0;
    const hasBankruptcies = !!(
      payload.response.Bankruptcies &&
      payload.response.Bankruptcies.TLOBankruptcy
    );

    const {
      relatives,
      firstname,
      middlename,
      lastname,
      city,
      state,
      addresses,
      age
    } = payload.response;

    // Format data a bit so it's usable
    payload.response = {
      firstName: helpers.setTitleCase(firstname),
      middleName: helpers.setTitleCase(middlename),
      lastName: helpers.setTitleCase(lastname),
      city: helpers.setTitleCase(city),
      providerId,
      state,
      age,
      addresses: addresses.map(
        address => `${helpers.setTitleCase(address.city)}, ${address.state}`
      )
    };

    const middle = payload.response.middleName
      ? ` ${payload.response.middleName}`
      : '';

    const name = `${payload.response.firstName}${middle} ${payload.response.lastName}`;

    payload.categoriesTwoD = this.getCategoriesTwoD(stepTwoD, {
      name,
      totalAssets,
      totalPhoneNumbers,
      hasBankruptcies
    });

    payload.relatives = !relatives
      ? []
      : relatives.map(obj => {
          const firstName = helpers.setTitleCase(obj.firstname);
          const lastName = helpers.setTitleCase(obj.lastname);
          // TODO: Remove once backend encodes the cids
          const cid =
            obj.cid.indexOf('-') > -1 ? this.encode(obj.cid) : obj.cid;

          return {
            ...obj,
            isChecked: false,
            name: `${firstName} ${lastName}`,
            value: cid,
            cid
          };
        });
    const newQuery = {
      providerID: payload.response.providerId,
      firstName: payload.response.firstName,
      lastName: payload.response.lastName,
      city: payload.response.city,
      state: payload.response.state
    };

    const newQueryParams = Object.keys(newQuery);
    const queryString = newQueryParams
      .map(key => key + '=' + newQuery[key])
      .join('&');

    let redirectUrl = stepUrl + '?' + queryString;

    if (providerId) {
      if (!firstNameQuery || !lastNameQuery) {
        redirect(302, redirectUrl);
      }
    }

    if (!payload) {
      return redirect(302, '/');
    }

    const userAgent = this.getUserAgentFromRequestOrNavigator(req);
    payload.isMobile = this.isMobile(userAgent);

    return payload;
  },
  async getCheckoutPayloadOrRedirect(
    { ChannelPeople },
    url,
    redirect,
    route,
    query,
    excludeLandingIds = [],
    $bugsnag = {}
  ) {
    const queryParams = Object.keys(query);
    const firstNameQuery = route.query.firstName || '';
    const lastNameQuery = route.query.lastName || '';
    const stateQuery = route.query.state || '';
    const cityQuery = route.query.city || '';
    const queryString = queryParams
      .map(key => {
        switch (key) {
          case 'firstName':
            query[key] = helpers
              .setTitleCase(firstNameQuery)
              .replace(helpers.spaceRegEx, '_')
              .replace(helpers.dashRegEx, '');
            break;
          case 'lastName':
            query[key] = helpers
              .setTitleCase(lastNameQuery)
              .replace(helpers.spaceRegEx, '_');
            break;
          case 'state':
            query[key] = helpers.getStateAbbrFromName(stateQuery);
            break;
          case 'city':
            query[key] = helpers.formatName(
              cityQuery.replace(helpers.underscoreRegEx, ' ')
            );
        }

        return key + '=' + query[key];
      })
      .join('&');

    const redirectUrl = url + '?' + queryString;
    if (shouldRedirectToResults({ route, excludeLandingIds })) {
      return redirect(302, redirectUrl);
    }

    const payload = {};

    try {
      const timeout = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), 3000)
      );
      payload.personResponse = await Promise.race([
        ChannelPeople.byCid(route.query.providerID),
        timeout
      ]);
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
      payload.personResponse = [];
    }
    // Prevent redirect if landingID is in excludeLandingIds. Keeps checkout page working without providerID
    if (excludeLandingIds.includes(route.params.landingID)) {
      return;
    }
    // redirect to results page if cid is invalid
    if (payload.personResponse.type) {
      return redirect(302, redirectUrl);
    }

    return {
      response: {
        firstName: helpers.formatName(payload.personResponse[0].firstname),
        middleName: helpers.formatName(payload.personResponse[0].middlename),
        lastName: helpers.formatName(payload.personResponse[0].lastname),
        age: payload.personResponse[0].age,
        city: helpers.formatName(payload.personResponse[0].city),
        state: payload.personResponse[0].state,
        phoneNumbers: payload.personResponse[0].phones,
        emails: payload.personResponse[0].emails,
        addresses: payload.personResponse[0].addresses,
        aliases: payload.personResponse[0].aliases,
        relatives: payload.personResponse[0].relatives,
        providerId: payload.personResponse[0].cid
      }
    };
  },
  checkUrlAndGetData(vm) {
    const hasCachedRes =
      vm.$route.query.cachedres && vm.$route.query.cachedres === 'true';
    const landingSession = this.getLandingSession();
    Object.keys(vm.$route.query).forEach(key => {
      if (
        !vm.$route.query[key] ||
        typeof vm.$route.query[key] === 'object' ||
        typeof vm.$route.query[key] === 'array'
      ) {
        return;
      }
      // Sanitize Query Key
      vm.$route.query[key] = this.sanitizeQueryInput(vm.$route.query[key]);
      if (
        vm.response &&
        !vm.response.hasOwnProperty(key) &&
        vm.response[key] !== undefined
      ) {
        // Sanitize Response Key
        vm.response[key] = this.sanitizeQueryInput(vm.response[key]);
      }
    });
    if (
      (hasCachedRes && !landingSession?.response) ||
      (hasCachedRes &&
        vm.$route.query.providerID &&
        landingSession?.response.providerId !== vm.$route.query.providerID)
    ) {
      window.location.href = window.location.href.replace(
        '&cachedres=true',
        ''
      );
    } else if (!hasCachedRes && vm.response) {
      this.setLandingSession({
        response: vm.response,
        providerId: vm.$route.query.providerID,
        firstName: vm.$route.query.firstName,
        lastName: vm.$route.query.lastName,
        city: vm.$route.query?.city || '',
        state: vm.$route.query?.state || '',
        aid: vm.$route.query.aid || this.$config?.defaultVars.aid,
        tid: vm.$route.query.tid || '',
        sid: vm.$route.query.sid || '',
        hitid: vm.$route.query.hitid || '',
        iv: vm.$route.query.iv || '',
        obcid: vm.$route.query.obcid || '',
        relatives: vm.$route.query.relatives
          ? vm.$route.query.relatives.split(',')
          : [],
        categoriesTwoD: vm.categoriesTwoD
      });
      vm.firstName = vm.response.firstName;
      vm.middleName = vm.response.middleName;
      vm.lastName = vm.response.lastName;
      vm.city = vm.response.city;
      vm.state = vm.response.state;
      vm.age = vm.response.age;
      vm.relatives = vm.response.relatives;
      vm.aliases = vm.response.aliases;
      vm.addresses = vm.response.addresses;
      vm.phoneNumbers = vm.response.phoneNumbers;
      vm.emails = vm.response.emails;
    } else {
      vm.firstName =
        landingSession?.response?.firstName ??
        setTitleCase({ text: landingSession.firstName });
      vm.middleName = landingSession?.response?.middleName ?? '';
      vm.lastName =
        landingSession?.response?.lastName ??
        setTitleCase({ text: landingSession.lastName });
      vm.city = landingSession?.response?.city ?? '';
      vm.state = landingSession?.response?.state ?? '';
      vm.age = landingSession?.response?.age ?? '';
      vm.relatives = landingSession?.response?.relatives ?? [];
      vm.aliases = landingSession?.response?.aliases ?? [];
      vm.addresses = landingSession?.response?.addresses ?? [];
      vm.phoneNumbers = landingSession?.response?.phoneNumbers ?? [];
      vm.emails = landingSession?.response?.emails ?? [];
      vm.categoriesTwoD = landingSession?.categoriesTwoD ?? [];
    }
  },
  getPersonTotalAssets(
    { CurrentPropertiesRollup, PastPropertiesRollup },
    $bugsnag = {}
  ) {
    let totalAssets = 0;

    try {
      totalAssets += Number(CurrentPropertiesRollup.TLOProperty.TotalValue);
    } catch (err) {
      // Do nothings
      $bugsnag?.notify(err);
    }

    try {
      for (let i = 0; i < PastPropertiesRollup.TLOProperty.length; i++) {
        totalAssets += Number(PastPropertiesRollup.TLOProperty[i].TotalValue);
      }
    } catch (err) {
      // Do nothings
      $bugsnag?.notify(err);
    }

    return totalAssets;
  },
  /**
   * Get categories two d for rendering multiple records found list
   * @param {string} name
   * @param {string|number} totalAssets
   * @param {number} totalPhoneNumbers
   * @param {boolean} hasBankruptcies
   * @returns {[]}
   */
  getCategoriesTwoD(
    stepTwoD,
    { name, totalAssets, totalPhoneNumbers, hasBankruptcies }
  ) {
    const { category_list: categoryList } = stepTwoD;
    const list = [];
    for (let i = 0; i < categoryList.length; i++) {
      const {
        category_name: categoryName,
        category_name_fallback: categoryNameFallback,
        type
      } = categoryList[i];
      let title = categoryName;
      // Handle all the types and fallback if falsy
      switch (type) {
        case 'crimes':
          title = name
            ? NBars.transform(categoryName, { name })
            : NBars.transform(categoryNameFallback, { name });
          break;
        case 'bankruptcy':
          title = hasBankruptcies
            ? NBars.transform(categoryName, { hasBankruptcies, name })
            : NBars.transform(categoryNameFallback, { name });
          break;
        case 'assets':
          title = totalAssets
            ? NBars.transform(categoryName, { totalAssets, name })
            : NBars.transform(categoryNameFallback, { name });
          break;
        case 'phone':
          title =
            totalPhoneNumbers > 1
              ? NBars.transform(categoryName, { totalPhoneNumbers, name })
              : NBars.transform(categoryNameFallback, { name });
          break;
      }
      list[i] = {
        title,
        setCheck: false,
        spinnerDelay: null,
        checkDelay: null,
        isStartingSpin: false,
        opacity: 0,
        isVisible: false
      };
    }

    return list;
  },
  setNameInText(text, name) {
    return NBars.transform(text, {
      name
    });
  },
  // TODO: Remove once backend encodes the cids
  encode(s) {
    let o = '';
    let n;

    for (let i = 0, ln = s.length; i < ln; i++) {
      n = s.charCodeAt(i).toString(16);
      o += n.length < 2 ? '0' + n : n;
    }

    return o;
  },
  /**
   * Sanitize input to prevent XSS attacks
   * @param {string} input
   * @returns {string}
   */
  sanitizeQueryInput(input) {
    const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'];

    // Replace any potentially harmful HTML tags with placeholders
    const sanitized = input.replace(
      /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
      (match, tag) => {
        return tags.includes(tag.toLowerCase()) ? match : `&lt;${tag}&gt;`;
      }
    );

    // Replace other characters that might be used for XSS
    const sanitizedValue = sanitized.replace(/[&<>"'`=\/]/g, char => {
      return `&#${char.charCodeAt(0)};`;
    });

    return sanitizedValue;
  }
};

export default landingHelper;
