const searchSdkServerUrl = process.env.SEARCH_SDK_SERVER_URL;
const searchSdkServerUrlV2 = process.env.SEARCH_SDK_SERVER_URL_V2;
const channelPeopleUrl = searchSdkServerUrl + '/channel/tlo/people';
const channelPeopleUrlV2 = searchSdkServerUrlV2 + '/channel/tlo/people';
const localPeopleFullUrl = searchSdkServerUrl + '/local/people/full';
const localPeopleUrl = searchSdkServerUrl + '/local/people';
const localNamesbucketUrl = searchSdkServerUrl + '/local/name-bucket';
const localNamesUrl = searchSdkServerUrl + '/local/names';
const localPhonesUrl = searchSdkServerUrl + '/local/phones';
const localIdUrl = searchSdkServerUrl + '/local/id';

const handleGetFetch = function(uri) {
  const headers = {};
  // Server side can access env variables directly, client side requires env variables from nuxt-config/index.js env object
  headers['tzsearch_domain'] =
    process.env.ENVIRONMENT === 'production' ||
    process.env.environment === 'production'
      ? `${process.env.website}.com`
      : process.env.TESTING_ACCOUNT_DOMAIN ||
        process.env.testingAccountDomain ||
        'traziventures.com';

  return fetch(uri, {
    method: 'GET',
    headers
  });
};
const getLocationPath = function(data) {
  let path = '';

  if (data.state) {
    path += `/state/${data.state}`;
  }

  if (data.city) {
    path += `/city/${data.city}`;
  }

  if (data.street) {
    path += `/street/${data.street}`;
  }

  if (data.phone) {
    path += `/phone/${data.phone}`;
  }

  if (data.email) {
    path += `/email/${data.email}`;
  }

  if (data.streetname) {
    path += `/streetname/${data.streetname}/streettype/${data.streettype}`;
  }

  return path;
};
const getNamePath = function(data) {
  return `/lastname/${data.lastName}/firstname/${data.firstName}`;
};
const getParamsPath = function(data) {
  const skipParams = [
    'lastName',
    'firstName',
    'city',
    'state',
    'id',
    'phone',
    'street',
    'email',
    'streetname',
    'streettype',
    'address'
  ];
  let params = '';

  for (const [key, value] of Object.entries(data)) {
    if (skipParams.indexOf(key) < 0) {
      const starter = params.length === 0 ? '?' : '&';
      params += `${starter}${key}=${value}`;
    }
  }

  return params;
};
const searchSDK = {
  LocalNamebucket: {
    byName: data => {
      return handleGetFetch(
        `${localNamesbucketUrl}${getNamePath(data)}${getParamsPath(data)}`
      )
        .then(res => res.text())
        .then(data => data);
    }
  },
  LocalNames: {
    countByCity: data => {
      return handleGetFetch(
        `${localNamesUrl}/count${getLocationPath(data)}/city${getNamePath(
          data
        )}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    countByState: data => {
      return handleGetFetch(
        `${localNamesUrl}/count/state${getNamePath(data)}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    namesCount: data => {
      return handleGetFetch(
        `${localNamesUrl}/count${getNamePath(data)}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    indexPage: data => {
      return handleGetFetch(
        `${localNamesUrl}/page/calc/${process.env.website}${getNamePath(data)}`
      ).then(res => res.json());
    },
    indexPageByLastName: data => {
      return handleGetFetch(
        `${localNamesUrl}/page/calc/${process.env.website}/lastname/${data.lastName}`
      ).then(res => res.json());
    },
    firstNames: data => {
      return handleGetFetch(
        `${localNamesUrl}/firstnames/list/lastname/${
          data.lastName
        }${getParamsPath(data)}`
      ).then(res => res.json());
    }
  },
  LocalPhones: {
    byPhone: phone => {
      return handleGetFetch(`${localPhonesUrl}/phone/${phone}`).then(res =>
        res.json()
      );
    }
  },
  ChannelPeople: {
    byCid: cid => {
      return handleGetFetch(`${channelPeopleUrl}/cid/${cid}`).then(res =>
        res.json()
      );
    },
    byLocation: data => {
      return handleGetFetch(
        `${channelPeopleUrl}${getNamePath(data)}${getLocationPath(
          data
        )}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    byName: data => {
      return handleGetFetch(
        `${channelPeopleUrl}${getNamePath(data)}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    Client: {
      byCid: cid => {
        return handleGetFetch(
          `${window.location.origin}/api/search/dashboard/people/cid/${cid}`
        ).then(res => res.json());
      },
      byName: data => {
        return handleGetFetch(
          `${window.location.origin}/api/search/dashboard/people${getNamePath(
            data
          )}${getParamsPath(data)}`
        ).then(res => res.json());
      },
      byLocation: data => {
        return handleGetFetch(
          `${window.location.origin}/api/search/dashboard/people${getNamePath(
            data
          )}${getLocationPath(data)}${getParamsPath(data)}`
        ).then(res => res.json());
      }
    }
  },
  ChannelPeopleFull: {
    byCid: cid => {
      return handleGetFetch(`${channelPeopleUrl}/full/cid/${cid}`).then(res =>
        res.json()
      );
    },
    byId: id => {
      return handleGetFetch(`${channelPeopleUrl}/full/id/${id}`).then(res =>
        res.json()
      );
    }
  },
  LocalPeopleFull: {
    byId: data => {
      return handleGetFetch(
        `${localPeopleFullUrl}/id/${data.id}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    byName: data => {
      return handleGetFetch(
        `${localPeopleFullUrl}${getNamePath(data)}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    byLocation: data => {
      return handleGetFetch(
        `${localPeopleFullUrl}${getNamePath(data)}${getLocationPath(
          data
        )}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    byPhone: data => {
      return handleGetFetch(
        `${localPeopleFullUrl}${getLocationPath(data)}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    byStreet: data => {
      return handleGetFetch(
        `${localPeopleFullUrl}${getLocationPath(data)}${getParamsPath(data)}`
      ).then(res => res.json());
    },
    // Used for the Dashboard results page
    Client: {
      byPhone: data => {
        return handleGetFetch(
          `${window.location.origin}/api/search/dashboard${getLocationPath(
            data
          )}${getParamsPath(data)}`
        ).then(res => res.json());
      },
      byStreet: data => {
        return handleGetFetch(
          `${
            window.location.origin
          }/api/search/dashboard/address${getLocationPath(data)}${getParamsPath(
            data
          )}`
        ).then(res => res.json());
      },
      byEmail: data => {
        return handleGetFetch(
          `${window.location.origin}/api/search/dashboard${getLocationPath(
            data
          )}${getParamsPath(data)}`
        ).then(res => res.json());
      }
    }
  },
  LocalId: {
    byCid: cid => {
      return handleGetFetch(`${localIdUrl}/cid/${cid}`).then(res => res.json());
    }
  },
  LocalPeople: {
    byId: id => {
      return handleGetFetch(`${localPeopleUrl}/id/${id}`).then(res =>
        res.json()
      );
    },
    byStreet: data => {
      return handleGetFetch(
        `${localPeopleUrl}${getLocationPath(data)}`
      ).then(res => res.json());
    }
  },
  // search v2 endpoints
  V2: {
    ChannelPeople: {
      byCid: cid => {
        return handleGetFetch(`${channelPeopleUrlV2}/cid/${cid}`).then(res =>
          res.json()
        );
      },
      byLocation: data => {
        return handleGetFetch(
          `${channelPeopleUrlV2}${getNamePath(data)}${getLocationPath(
            data
          )}${getParamsPath(data)}`
        ).then(res => res.json());
      },
      byName: data => {
        return handleGetFetch(
          `${channelPeopleUrlV2}${getNamePath(data)}${getParamsPath(data)}`
        ).then(res => res.json());
      },
      Client: {
        byCid: cid => {
          return handleGetFetch(
            `${window.location.origin}/api/search/dashboard/v2/people/cid/${cid}`
          ).then(res => res.json());
        },
        byName: data => {
          return handleGetFetch(
            `${
              window.location.origin
            }/api/search/dashboard/v2/people${getNamePath(data)}${getParamsPath(
              data
            )}`
          ).then(res => res.json());
        },
        byLocation: data => {
          return handleGetFetch(
            `${
              window.location.origin
            }/api/search/dashboard/v2/people${getNamePath(
              data
            )}${getLocationPath(data)}${getParamsPath(data)}`
          ).then(res => res.json());
        }
      }
    },
    ChannelPhones: {
      Client: {
        comprehensiveReport: ({ phone }) => {
          return handleGetFetch(
            `${window.location.origin}/api/search/dashboard/v2/phone/report/${phone}`
          ).then(res => res.json());
        }
      }
    },
    ChannelAddresses: {
      Client: {
        comprehensiveReport: ({ zip, state, city, street }) => {
          return handleGetFetch(
            `${window.location.origin}/api/search/dashboard/v2/address/zip/${zip}/state/${state}/city/${city}/street/${street}`
          ).then(res => res.json());
        }
      }
    }
  }
};
export default searchSDK;
