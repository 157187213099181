import sdk from '@trazi/opt-out/sdk/node';

/**
 * Returns a list of Provider IDs from the opt-out SDK.
 * @returns {array}
 */
const getOptOutProviderIds = async () => {
  const optOutSDK = sdk(`${process.env.BASE_URL}/api`);
  try {
    const response = await optOutSDK.LocalDomain.getLandingList(
      {},
      `${process.env.WEBSITE}.com`
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export default getOptOutProviderIds;
