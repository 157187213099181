import sharedLandingHelper from '@/assets/js/shared/landingHelper';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import getStateAbbrFromName from '@/assets/js/shared/helpers/getStateAbbrFromName';
import formatNameCaseWithSplitterAndSpacer from '@/assets/js/shared/helpers/formatNameCaseWithSplitterAndSpacer';
import formatFullName from '@/assets/js/shared/helpers/formatFullName';
import renderIfReturnArray from '@/assets/js/shared/helpers/renderIfReturnArray';
const NBars = require('nbars/commonjs.js');
import helpers from '@/assets/js/shared/misc/helpers';
import formatCity from '@/assets/js/shared/helpers/formatCity';

import getAge from '@/assets/js/shared/helpers/getAge';
import getOptOutProviderIds from './shared/helpers/getOptOutProviderIds';

// Opt out list of provider IDs
let optOuts = [];

const landingHelper = {
  ...helpers,
  ...sharedLandingHelper,
  formatFullName,
  renderIfReturnArray,
  setTitleCase,
  setNameInText(text, name) {
    return NBars.transform(text, {
      name
    });
  },
  async getPayloadOrRedirect(
    { ChannelPeople },
    stepUrl,
    url,
    redirect,
    route,
    query,
    payload = {},
    $bugsnag = {}
  ) {
    payload.itemsPerPage = 12;
    payload.maxPages = 1;
    payload.limit = payload.itemsPerPage * payload.maxPages;

    const queryParams = Object.keys(query);
    let providerId = route.query.providerID;
    let firstNameQuery = route.query.firstName || '';
    let lastNameQuery = route.query.lastName || '';
    let stateQuery = route.query.state || '';
    let cityQuery = route.query.city || '';

    // If provider ID is in opt out list, redirect to home page
    try {
      optOuts = await getOptOutProviderIds();
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
    }
    if (optOuts.includes(providerId)) {
      return redirect(302, '/');
    }

    const formatQueryParams = function(params) {
      return params
        .map(key => {
          // Do NOT return query param if empty to prevent null/undefined as string value on results page
          if (!query[key]) {
            return;
          }
          switch (key) {
            case 'firstName':
              query[key] = setTitleCase({ text: firstNameQuery })
                .replace(getRegex({ regexType: 'spaceRegEx' }), '_')
                .replace(getRegex({ regexType: 'dashRegEx' }), '');
              break;
            case 'lastName':
              query[key] = setTitleCase({ text: lastNameQuery }).replace(
                getRegex({ regexType: 'spaceRegEx' }),
                '_'
              );
              break;
            case 'state':
              query[key] = getStateAbbrFromName({ state: stateQuery });
              break;
            case 'city':
              query[key] = formatNameCaseWithSplitterAndSpacer({
                name: cityQuery.replace(
                  getRegex({ regexType: 'underscoreRegEx' }),
                  ' '
                )
              });
              break;
          }
          return key + '=' + query[key];
        })
        .join('&');
    };

    // If no provider ID, first name and last name, redirect to results page
    if (!providerId || (!firstNameQuery && !lastNameQuery)) {
      const queryString = formatQueryParams(queryParams);
      let redirectUrl = url + '?' + queryString;

      return redirect(302, redirectUrl);
    }

    try {
      const timeout = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), 3000)
      );
      payload.response = await Promise.race([
        ChannelPeople.byCid(providerId),
        timeout
      ]);
      if (
        payload.response &&
        payload.response.type === 'error-tlo-people-cid'
      ) {
        throw Error(payload.response.msg);
      }
    } catch (err) {
      // Log error and redirect since something went wrong
      console.error(err);
      $bugsnag?.notify(err);
      const providerIdIndex = queryParams.indexOf('providerID');
      queryParams.splice(providerIdIndex, 1);
      // Format the query params
      const queryString = formatQueryParams(queryParams);
      // Create redirect url
      let redirectUrl = url + '?' + queryString;
      // Redirect to Results Page
      return redirect(302, redirectUrl);
    }
    const {
      relatives,
      firstname,
      middlename,
      lastname,
      city,
      state,
      age,
      cid,
      addresses,
      aliases,
      phones: phoneNumbers,
      emails
    } = payload.response[0];
    // Format data a bit so it's usable
    payload.response = {
      firstName: setTitleCase({ text: firstname }),
      middleName: setTitleCase({ text: middlename }),
      lastName: setTitleCase({ text: lastname }),
      city: setTitleCase({ text: city }),
      providerId: cid,
      age,
      state,
      addresses,
      aliases,
      phoneNumbers,
      emails
    };
    payload.response.relatives = !relatives
      ? []
      : relatives.map(obj => {
          const firstName = setTitleCase({ text: obj.firstname });
          const lastName = setTitleCase({ text: obj.lastname });

          return {
            ...obj,
            isChecked: false,
            name: `${firstName} ${lastName}`,
            value: obj.cid
          };
        });
    const newQuery = {
      providerID: payload.response.providerId,
      firstName: payload.response.firstName,
      lastName: payload.response.lastName,
      city: payload.response.city,
      state: payload.response.state
    };

    const newQueryParams = Object.keys(newQuery);
    const queryString = newQueryParams
      .map(key => key + '=' + newQuery[key])
      .join('&');

    let redirectUrl = stepUrl + '?' + queryString;

    if (providerId) {
      if (!firstNameQuery || !lastNameQuery) {
        redirect(302, redirectUrl);
      }
    }

    if (!payload) {
      return redirect(302, '/');
    }

    return payload;
  },
  /**
   * Checks the url query param cachedres to see if landing session available with data, and sets data props
   * @param vm vue context
   */
  checkUrlAndGetData(vm) {
    const hasCachedRes =
      vm.$route.query.cachedres && vm.$route.query.cachedres === 'true';
    const landingSession = this.getLandingSession();
    Object.keys(vm.$route.query).forEach(key => {
      if (
        !vm.$route.query[key] ||
        typeof vm.$route.query[key] === 'object' ||
        typeof vm.$route.query[key] === 'array'
      ) {
        return;
      }
      // Sanitize Query Key
      vm.$route.query[key] = this.sanitizeQueryInput(vm.$route.query[key]);
      if (
        vm.response &&
        !vm.response.hasOwnProperty(key) &&
        vm.response[key] !== undefined
      ) {
        // Sanitize Response Key
        vm.response[key] = this.sanitizeQueryInput(vm.response[key]);
      }
    });
    if (
      (hasCachedRes && !landingSession?.response) ||
      (hasCachedRes &&
        vm.$route.query.providerID &&
        landingSession?.response.providerId !== vm.$route.query.providerID)
    ) {
      window.location.href = window.location.href.replace(
        '&cachedres=true',
        ''
      );
    } else if (!hasCachedRes && vm.response) {
      this.setLandingSession({
        response: vm.response,
        providerId: vm.$route.query.providerID,
        firstName: vm.$route.query.firstName,
        lastName: vm.$route.query.lastName,
        city: vm.$route.query?.city || '',
        state: vm.$route.query?.state || '',
        aid: vm.$route.query.aid || this.$config?.defaultVars.aid,
        tid: vm.$route.query.tid || '',
        sid: vm.$route.query.sid || '',
        hitid: vm.$route.query.hitid || '',
        iv: vm.$route.query.iv || '',
        obcid: vm.$route.query.obcid || '',
        relatives: vm.$route.query.relatives
          ? vm.$route.query.relatives.split(',')
          : [],
        categoriesTwoD: vm.categoriesTwoD
      });
      vm.firstName = vm.response.firstName;
      vm.middleName = vm.response.middleName;
      vm.lastName = vm.response.lastName;
      vm.city = vm.response.city;
      vm.state = vm.response.state;
      vm.age = vm.response.age;
      vm.relatives = vm.response.relatives;
      vm.aliases = vm.response.aliases;
      vm.addresses = vm.response.addresses;
      vm.phoneNumbers = vm.response.phoneNumbers;
      vm.emails = vm.response.emails;
    } else {
      vm.firstName =
        landingSession?.response?.firstName ??
        setTitleCase({ text: landingSession.firstName });
      vm.middleName = landingSession?.response?.middleName ?? '';
      vm.lastName =
        landingSession?.response?.lastName ??
        setTitleCase({ text: landingSession.lastName });
      vm.city = landingSession?.response?.city ?? '';
      vm.state = landingSession?.response?.state ?? '';
      vm.age = landingSession?.response?.age ?? '';
      vm.relatives = landingSession?.response?.relatives ?? [];
      vm.aliases = landingSession?.response?.aliases ?? [];
      vm.addresses = landingSession?.response?.addresses ?? [];
      vm.phoneNumbers = landingSession?.response?.phoneNumbers ?? [];
      vm.emails = landingSession?.response?.emails ?? [];
      vm.categoriesTwoD = landingSession?.categoriesTwoD ?? [];
    }
  },
  /**
   * Gets Landing Page Page Title and Meta
   * @param {object} opts
   * @param {string} opts.city
   * @param {string} opts.state
   * @param {string} opts.firstName
   * @param {string} opts.lastName
   * @param {number} opts.stepNumber
   * @param {string} opts.pageTitleCms
   * @param {array} opts.metaArr
   * @returns {null|object}
   */
  getPageMetaAndTitle(opts) {
    if (!opts) {
      return {};
    }
    const city =
      opts.city && opts.city !== 'null'
        ? formatCity({ city: opts.city })
        : null;
    const state =
      opts.state && opts.state !== 'null' ? opts.state.toUpperCase() : null;

    const location = this.getMetaLocation({
      city,
      state
    });

    const title = this.getPageTitle({
      location,
      firstName: opts.firstName,
      lastName: opts.lastName,
      stepNumber: opts.stepNumber,
      pageTitleCms: opts.pageTitleCms
    });

    const transformedMeta = this.getPageMeta({
      location,
      firstName: opts.firstName,
      lastName: opts.lastName,
      stepNumber: opts.stepNumber,
      metaArr: opts.metaArr
    });

    return {
      title: title,
      meta: transformedMeta
    };
  },
  /**
   * Gets Landing Page Page Title
   * @param {object} opts
   * @param {string} opts.city
   * @param {string} opts.state
   * @returns {null|string}
   */
  getMetaLocation(opts) {
    if (!opts) {
      return '';
    }
    return (
      (opts.city &&
        opts.state &&
        `in ${this.formatUnderScoreToSpace(opts.city)}, ${opts.state} `) ||
      (opts.state && !opts.city && `in ${opts.state} `) ||
      ''
    );
  },
  /**
   * Gets Landing Page Page Title
   * @param {object} opts
   * @param {string} opts.location
   * @param {string} opts.firstName
   * @param {string} opts.lastName
   * @param {number} opts.stepNumber
   * @param {string} opts.pageTitleCms
   * @returns {null|string}
   */
  getPageTitle(opts) {
    if (!opts) {
      return '';
    }
    return NBars.transform(opts.pageTitleCms, {
      number: opts.stepNumber,
      firstName: opts.firstName,
      lastName: opts.lastName,
      location: opts.location
    });
  },
  /**
   * Gets Landing Page Meta
   * @param {object} opts
   * @param {string} opts.location
   * @param {string} opts.firstName
   * @param {string} opts.lastName
   * @param {number} opts.stepNumber
   * @param {string} opts.location
   * @param {array} opts.metaArr
   * @returns {null|array}
   */
  getPageMeta(opts) {
    if (!opts) {
      return [];
    }
    const transformedMeta = [];
    for (let i = 0; i < opts.metaArr.length; i++) {
      const metaCopy = Object.assign({}, opts.metaArr[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = NBars.transform(content, {
            number: opts.stepNumber,
            firstName: opts.firstName,
            lastName: opts.lastName,
            location: opts.location
          });
      }

      transformedMeta[i] = metaCopy;
    }
    return transformedMeta;
  },
  /**
   * Sanitize input to prevent XSS attacks
   * @param {string} input
   * @returns {string}
   */
  sanitizeQueryInput(input) {
    const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'];

    // Replace any potentially harmful HTML tags with placeholders
    const sanitized = input.replace(
      /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
      (match, tag) => {
        return tags.includes(tag.toLowerCase()) ? match : `&lt;${tag}&gt;`;
      }
    );

    // Replace other characters that might be used for XSS
    const sanitizedValue = sanitized.replace(/[&<>"'`=\/]/g, char => {
      return `&#${char.charCodeAt(0)};`;
    });

    return sanitizedValue;
  },
  /**
   * Gets alternate landing pages' data via endpoint server side
   */
  async getAltPayloadOrRedirect(
    { LocalPeopleFull },
    stepUrl,
    url,
    redirect,
    route,
    query,
    req,
    stepTwoD,
    payload = {},
    $bugsnag = {}
  ) {
    payload.itemsPerPage = 12;
    payload.maxPages = 1;
    payload.limit = payload.itemsPerPage * payload.maxPages;
    const queryParams = query ? Object.keys(query) : [];
    let providerId = route.query.providerID;
    let firstNameQuery = setTitleCase({ text: route.query.firstName }) ?? '';
    let lastNameQuery = setTitleCase({ text: route.query.lastName }) ?? '';
    let stateQuery = route.query.state || '';
    let cityQuery = route.query.city || '';
    const landingId = route.params.landingID || '';

    // If provider ID is in opt out list, redirect to home page
    try {
      optOuts = await getOptOutProviderIds();
    } catch (err) {
      console.error(err);
      $bugsnag?.notify(err);
    }
    if (optOuts.includes(providerId)) {
      return redirect(302, '/');
    }

    const formatQueryParams = function(params) {
      return params
        .map(key => {
          if (key === 'firstName') {
            query[key] = helpers
              .setTitleCase(firstNameQuery)
              .replace(helpers.spaceRegEx, '_')
              .replace(helpers.dashRegEx, '');
          }

          if (key === 'lastName') {
            query[key] = helpers
              .setTitleCase(lastNameQuery)
              .replace(helpers.spaceRegEx, '_');
          }

          // Do not return query param if empty to prevent null/undefined as string value on results page
          if (!query[key]) {
            return;
          }

          if (key === 'state') {
            query[key] = helpers.getStateAbbrFromName(stateQuery);
          }

          if (key === 'city') {
            query[key] = helpers.formatName(
              cityQuery.replace(helpers.underscoreRegEx, ' ')
            );
          }

          return key + '=' + query[key];
        })
        .join('&');
    };
    const altLandingIds = [
      'gr05eg',
      'capt06',
      '1ll1ca',
      '1ll1rp',
      '3lv1s',
      'h4rm0',
      '81llv2',
      '81nr0',
      'c4l1',
      'r1t3s',
      'r0b3rt',
      'r0bpr0g',
      'n0f0rm',
      '8170',
      'r0bf0rm',
      'c0p1',
      'c0p2'
    ];
    // If no provider ID, redirect to correct page
    if (!altLandingIds.includes(landingId) && !providerId) {
      // No name redirects to home page
      if (!firstNameQuery || !lastNameQuery) {
        return redirect(302, '/');
      }

      // Otherwise redirect to results page
      const queryString = formatQueryParams(queryParams);

      let redirectUrl = url + '?' + queryString;

      return redirect(302, redirectUrl);
    }
    if (providerId) {
      try {
        const timeout = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Request timed out')), 3000)
        );
        payload.response = (
          await Promise.race([
            LocalPeopleFull.byId({
              domain: `${process.env.website}.com`,
              id: providerId,
              filter:
                'firstname, lastname, state, city, zipcode, dob, relatives, addresses, has_assets, has_phones, has_bankruptcy'
            }),
            timeout
          ])
        )[0];
      } catch (err) {
        // Log error and redirect since something went wrong
        console.error('Response:', err);
        $bugsnag?.notify(err);
        payload.response = {};
      }
    } else {
      payload.response = {};
    }

    const totalPhoneNumbers =
      payload.response && payload.response.has_phones
        ? payload.response.has_phones
        : 0;
    const hasBankruptcies = !!(
      payload.response && payload.response.has_bankruptcy
    );

    const {
      relatives = [],
      firstname = '',
      middlename = '',
      lastname = '',
      city = '',
      state = '',
      addresses = [],
      dob = 0
    } = payload.response || {};
    // Format data a bit so it's usable
    payload.response = {
      firstName: firstname ? helpers.setTitleCase(firstname) : firstNameQuery,
      middleName: middlename ? helpers.setTitleCase(middlename) : '',
      lastName: lastname ? helpers.setTitleCase(lastname) : lastNameQuery,
      city: city ? helpers.setTitleCase(city) : cityQuery,
      providerId: providerId ?? '',
      state: state ? state : stateQuery,
      age: dob ? getAge({ dateString: dob }) : null,
      addresses: addresses.length
        ? addresses.map(
            address => `${helpers.setTitleCase(address.city)}, ${address.state}`
          )
        : []
    };

    const middle = payload.response.middleName
      ? ` ${payload.response.middleName}`
      : '';

    const name = `${payload.response.firstName}${middle} ${payload.response.lastName}`;

    payload.categoriesTwoD = this.getCategoriesTwoD(stepTwoD, {
      name,
      totalAssets: null,
      totalPhoneNumbers,
      hasBankruptcies
    });

    payload.response.relatives = !relatives
      ? []
      : relatives.map(obj => {
          const firstName = helpers.setTitleCase(obj.firstname);
          const lastName = helpers.setTitleCase(obj.lastname);
          // TODO: Remove once backend encodes the cids
          const cid =
            obj.cid.indexOf('-') > -1 ? this.encode(obj.cid) : obj.cid;

          return {
            ...obj,
            isChecked: false,
            name: `${firstName} ${lastName}`,
            value: cid,
            cid
          };
        });

    const newQuery = {
      providerID: payload.response.providerId,
      firstName: payload.response.firstName,
      lastName: payload.response.lastName,
      city: payload.response.city,
      state: payload.response.state
    };

    const newQueryParams = Object.keys(newQuery);
    const queryString = newQueryParams
      .map(key => key + '=' + newQuery[key])
      .join('&');

    let redirectUrl = stepUrl + '?' + queryString;

    if (providerId) {
      if (!firstNameQuery || !lastNameQuery) {
        redirect(302, redirectUrl);
      }
    }

    if (!payload) {
      return redirect(302, '/');
    }

    const userAgent = this.getUserAgentFromRequestOrNavigator(req);
    payload.isMobile = this.isMobile(userAgent);

    return payload;
  }
};

export default landingHelper;
