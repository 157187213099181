/**
 * Checks if we should redirect to the results page
 * @params {object} opts
 * @params {object} opts.route
 * @params {array} opts.excludeLandingIds
 * @returns {boolean}
 */
const shouldRedirectToResults = (opts = {}) => {
  if (!opts || !opts.route || !opts.excludeLandingIds) {
    return false;
  }

  if (opts.excludeLandingIds && Array.isArray(opts.excludeLandingIds)) {
    for (const param of opts.excludeLandingIds) {
      if (opts.route.params.landingID === param) {
        return false;
      }
    }
  }

  if (opts.route.query && !opts.route.query.providerID) {
    return true;
  }

  return false;
};

export default shouldRedirectToResults;
